import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import SentMail from '../components/sent-mail'

class ThanksIndex extends React.Component {
  render() {
    const [hero] = get(this, 'props.data.allContentfulHero.nodes')

    return (
      <Layout location={this.props.location}>
        <SentMail
          image={hero.heroImage.gatsbyImageData}
          title={hero.title}
         />
      </Layout>
    )
  }
}

export default ThanksIndex

export const pageQuery = graphql`
  query ThanksQuery {
    allContentfulHero {
      nodes {
        heroImage: image {
          gatsbyImageData(width: 1180, placeholder: BLURRED, layout: CONSTRAINED)
        }
        title
      }
    }
  }
`
