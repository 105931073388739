import React from 'react'
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './sent-mail.module.css'

const SentMail = ({ image, title }) => {

  return (
    <div>
      <div className={styles.hero}>
        {image && (
          <GatsbyImage className={styles.image} alt={title} image={image} />
        )}
      </div>
      <div className={styles.message}>
        メールを送信しました。
        返信をお待ちください。
      </div>
      <div className={styles.link}>
        <Link to="/">TOPに戻る</Link>
      </div>
    </div>
  )
}

export default SentMail
